import createHistory from 'history/createBrowserHistory';
import ReactGA from 'react-ga';

const timeout = 500;
const historyExitingEventType = `history::exiting`;

const getUserConfirmation = (pathname, callback) => {
  const event = new CustomEvent(historyExitingEventType, { detail: { pathname } });
  window.dispatchEvent(event);
  setTimeout(() => {
    callback(true);
  }, timeout);
};

let history;
if (typeof document !== 'undefined') {
  history = createHistory({ getUserConfirmation });
  // block must return a string to conform
  history.block(location => location.pathname);
}

export const replaceHistory = () => history;
export { historyExitingEventType, timeout };

const { NODE_ENV, GOOGLE_ANALYTICS_TRACKING_CODE } = process.env;

if (GOOGLE_ANALYTICS_TRACKING_CODE) {
  ReactGA.initialize(GOOGLE_ANALYTICS_TRACKING_CODE, {
    testMode: NODE_ENV === 'development',
    debug: NODE_ENV === 'development'
  });
}

export const onRouteUpdate = state => {
  ReactGA.pageview(state.location.pathname);
};
